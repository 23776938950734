import { camelCase, upperFirst } from "lodash";

export default function importComponentsByType(rawSections = []) {
  // Imports sections components by sction type prefix (layout or public)
  // using Mixin merging.
  // https://vuejs.org/v2/guide/mixins.html#Option-Merging.

  const sections = [];
  const components = rawSections.reduce((acc, cur) => {
    const sectionType = cur.split("-")[0];
    const nameWithoutType = cur.substr(cur.indexOf("-") + 1);
    const name = upperFirst(camelCase(nameWithoutType));

    if (sectionType === "public") {
      acc[`Section${name}`] = () => import(`@/views/public/sections/${name}`);
      sections.push(nameWithoutType);
    } else if (sectionType === "layout") {
      acc[`Section${name}`] = () => import(`@/layouts/${name}`);
      sections.push(nameWithoutType);
    } else {
      throw "Section prefix is not valid!";
    }

    return acc;
  }, {});

  return {
    components,
    data: () => ({ sections }),
  };
}
